import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css, keyframes } from "@emotion/react"

import Seo from "../components/SEO"
import MedalloForm from "../components/medalloForm"
import darkWaveImg from "../../static/wavy_dark.svg"
import highVoltage from "../images/icons/highVoltage.svg"
import artistPalette from "../images/icons/artistPalette.svg"
import clipboard from "../images/icons/clipboard.svg"
import computerMouse from "../images/icons/computerMouse.svg"
import explodingHead from "../images/icons/explodingHead.svg"
import fountainPen from "../images/icons/fountainPen.svg"
import framedPicture from "../images/icons/framedPicture.svg"
import key from "../images/icons/key.svg"
import locked from "../images/icons/locked.svg"
import magnifyingGlass from "../images/icons/magnifyingGlass.svg"
import paintbrush from "../images/icons/paintbrush.svg"

import Footer from "../components/footer"
import LocalizedLink from "../components/localizedLink"

const matchIcon = (iconName, num, featureNum) => {
  let icon = null
  const primary = "w-32 h-32"
  const secondary =
    "w-6 h-6 left-0 top-3 md:w-8 md:h-8 absolute md:top-0 md:left-6 rotate-6 "
  const third =
    "w-7 h-7  bottom-2 md:w-12 md:h-12 absolute md: bottom-0 right-0  -rotate-6 "
  const className = num === 1 ? primary : num === 2 ? secondary : third
  const cssAnimate =
    num === 1
      ? null
      : num === 2
      ? css`
          animation: ${bounce} 5s ease infinite;
          animation-delay: ${-(num + featureNum * 2) / 2.2 + "s"};
        `
      : css`
          animation: ${bounce2} 15s ease infinite;
          animation-delay: ${-(num + featureNum * 2) / 2.2 + "s"};
        `
  switch (iconName) {
    case "highVoltage":
      icon = (
        <img src={highVoltage} alt="" className={className} css={cssAnimate} />
      )
      break
    case "artistPalette":
      icon = (
        <img
          src={artistPalette}
          alt=""
          className={className}
          css={cssAnimate}
        />
      )
      break
    case "clipboard":
      icon = (
        <img src={clipboard} alt="" className={className} css={cssAnimate} />
      )
      break
    case "computerMouse":
      icon = (
        <img
          src={computerMouse}
          alt=""
          className={className}
          css={cssAnimate}
        />
      )
      break
    case "explodingHead":
      icon = (
        <img
          src={explodingHead}
          alt=""
          className={className}
          css={cssAnimate}
        />
      )
      break
    case "fountainPen":
      icon = (
        <img src={fountainPen} alt="" className={className} css={cssAnimate} />
      )
      break
    case "framedPicture":
      icon = (
        <img
          src={framedPicture}
          alt=""
          className={className}
          css={cssAnimate}
        />
      )
      break
    case "key":
      icon = <img src={key} alt="" className={className} css={cssAnimate} />
      break
    case "locked":
      icon = <img src={locked} alt="" className={className} css={cssAnimate} />
      break
    case "magnifyingGlass":
      icon = (
        <img
          src={magnifyingGlass}
          alt=""
          className={className}
          css={cssAnimate}
        />
      )
      break
    case "paintbrush":
      icon = (
        <img src={paintbrush} alt="" className={className} css={cssAnimate} />
      )
      break

    default:
      break
  }

  return icon
}

const Success = ({ medallo, data, location, locale, alternateLanguages }) => {
  console.log("medallo", medallo)
  return (
    <>
      <div
        className={`section  flex flex-col gradient-dark 
        relative h-80 md:min-h-600 0 pt-24   md:pt-52`}
      >
        <div
          className={`text-3xl max-w-5xl font-sans font-bold m-auto px-10 my-8 relative z-10 text-center text-white  
          md:text-5xl md:mt-10 md:mb-2`}
          dangerouslySetInnerHTML={{ __html: medallo.data.success_forn.html }}
          css={strongCss}
        />

        <img
          className="absolute -bottom-4 w-full z-0"
          src={darkWaveImg}
          alt="wave"
        />
      </div>
      <Footer posts={data.allPrismicBlogpost} />
    </>
  )
}

const Feature = ({ feature, icons, i }) => {
  if (i === 3) return <FeatureCMS feature={feature} icons={icons} i={i} />
  return (
    <div
      key={i}
      className={`flex flex-col-reverse ${
        i % 2 ? "md:flex-row-reverse" : "md:flex-row"
      } my-12 justify-center items-center `}
    >
      <div
        className={`w-3/4  text-center mr-5 ${i % 2 ? " ml-5 md:text-left" : ""}
                md:w-auto`}
      >
        <div
          className="text-3xl font-sans font-bold text-white  m-auto "
          dangerouslySetInnerHTML={{
            __html: feature.feature_title.html,
          }}
          css={strongCss}
        />
        <div
          className="text-white   font-sans"
          dangerouslySetInnerHTML={{
            __html: feature.feature_paragraph.html,
          }}
        />
      </div>
      <div className="relative w-1/4 md:w-auto md:max-w-[130px]">
        {icons.icon1}
        {icons.icon2}
        {icons.icon3}
      </div>
    </div>
  )
}

const FeatureCMS = ({ feature, icons, i }) => {
  return (
    <LocalizedLink to={"starter/cms"}>
      <Feature feature={feature} icons={icons} i={33} />
    </LocalizedLink>
  )
}

const MedalloPresentation = ({
  medallo,
  data,
  location,
  locale,
  alternateLanguages,
}) => {
  return (
    <>
      <Seo
        title={medallo.data.seo_title.text}
        desc={medallo.data.seo_description.text}
        pathname={location.pathname}
        locale={locale}
        alternateLanguages={alternateLanguages}
      />
      <div
        className={`section  flex flex-col gradient-dark 
         relative h-80 md:min-h-600 0 pt-24   md:pt-52`}
      >
        <div
          className={`text-3xl max-w-5xl font-sans font-bold m-auto px-10 my-3 relative z-10 text-center text-white  
          md:text-5xl md:mt-10 md:mb-2`}
          dangerouslySetInnerHTML={{ __html: medallo.data.title.html }}
          css={strongCss}
        />

        <div
          className="text-2xl font-sans font-bold text-white text-center m-auto z-10 mb-10 py-0 px-5 my-0 md:text-4xl"
          dangerouslySetInnerHTML={{ __html: medallo.data.subtitle.html }}
          css={strongCss}
        />
        <img
          className="absolute -bottom-4 w-full z-0"
          src={darkWaveImg}
          alt="wave"
        />
      </div>
      <section className=" relative z-10 mx-auto mb-20 px-5   max-w-3xl flex flex-col md:flex md:items-center md:w-3/5 md:pb-36   ">
        {medallo.data.features.map((feature, i) => {
          const icon1 = matchIcon(feature.svg_id_1, 1, i)
          const icon2 = matchIcon(feature.svg_id_2, 2, i)
          const icon3 = matchIcon(feature.svg_id_3, 3, i)
          const icons = { icon1, icon2, icon3 }
          return <Feature feature={feature} icons={icons} i={i} />
        })}

        <MedalloForm
          form_title={medallo.data.form_title}
          form_submit={medallo.data.form_submit}
          title_nbr_pages={medallo.data.title_nbr_pages}
          form_features={medallo.data.form_features}
          location={location}
        />
      </section>
      <Footer posts={data.allPrismicBlogpost} />
    </>
  )
}

const Medallo = ({
  data,
  pageContext: { locale, alternateLanguages },
  location,
}) => {
  const medallo = data.prismicMedallopage
  console.log("location.search", location.search, location)
  return location.search === "?formSent=true" ? (
    <Success
      medallo={medallo}
      data={data}
      location={location}
      locale={locale}
      alternateLanguages={alternateLanguages}
    />
  ) : (
    <MedalloPresentation
      medallo={medallo}
      data={data}
      location={location}
      locale={locale}
      alternateLanguages={alternateLanguages}
    />
  )
}

export default Medallo

Medallo.propTypes = {
  data: PropTypes.shape({
    prismicMedallopage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

const strongCss = css`
  strong {
    background: linear-gradient(
      90deg,
      #f4c82b 3.44%,
      #ecb93d 47.7%,
      #ff6ad5 100%
    );
    background-clip: text;
    text-fill-color: transparent;
  }
`

const bounce = keyframes`
  from,  to {
    transform: translate3d(0,0,0) ;
  }
  50% {
    transform: translate3d(0,-12px,0) scale(1.2) rotate(5deg) ;
  }
  `
const bounce2 = keyframes`
  from,  to {
    transform: translate3d(0,0,0) ;
  }
  33% {
    transform: translate3d(5px,3px,0) scale(1.2) rotate(-8deg) ;
  }
  66%{
    transform: translate3d(12px,6px,0) scale(0.9) rotate(-15deg) ;

  }
  `

export const pageQuery = graphql`
  query MedalloQuery($locale: String!) {
    prismicMedallopage(lang: { eq: $locale }) {
      alternate_languages {
        uid
        lang
      }
      uid
      data {
        seo_title {
          text
        }
        seo_description {
          text
        }
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        features {
          feature_title {
            html
            text
          }
          feature_paragraph {
            html
            text
          }
          svg_id_1
          svg_id_2
          svg_id_3
        }
        form_title {
          html
          text
        }
        form_submit
        title_nbr_pages
        form_features {
          checkbox_feature
        }
        success_forn {
          html
          text
        }
      }
    }

    allPrismicBlogpost(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title {
              text
              html
            }
            body {
              ... on PrismicBlogpostDataBodyContent {
                id
                slice_type
                slice_label
                primary {
                  richtext {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
