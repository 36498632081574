import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { css, keyframes } from "@emotion/react"

const MedalloForm = ({
  form_title,
  form_submit,
  title_nbr_pages,
  form_features,
  location,
}) => {
  const [nbrPages, setNbrPages] = useState("")
  const [features, setFeatures] = useState([])
  const [email, setEmail] = useState("")

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  // const handleSubmit = event => {
  //   event.preventDefault()
  //   fetch("/", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //     body: encode({
  //       "form-name": event.target.getAttribute("name"),
  //       nbrPages,
  //       features: features,
  //       email,
  //     }),
  //   })
  //     .then(() => navigate("/"))
  //     .catch(error => alert(error))
  // }

  const handleCheckbox = name => {
    if (features.includes(name)) {
      setFeatures(features => features.filter(f => f !== name))
    } else {
      setFeatures([...features, name])
    }
  }

  // useEffect(() => {
  //   console.log(features, features.length)
  // }, [features])

  return (
    <>
      <div
        className="text-3xl font-sans font-bold text-white self-start mb-2 mt-10 md:px-8"
        dangerouslySetInnerHTML={{ __html: form_title.html }}
      />
      <form
        data-netlify="true"
        name="starterForm"
        method="post"
        action={location.pathname + "?formSent=true"}
      >
        <input type="hidden" name="form-name" value="starterForm" />
        <div className="flex flex-wrap">
          {form_features.map((check, i) => {
            const cssName = check.checkbox_feature.slice(0, 3).toLowerCase()
            return (
              <div className="relative" key={i}>
                <input
                  type="checkbox"
                  id={cssName}
                  name={"features"}
                  value={check.checkbox_feature}
                  className={`hidden`}
                  css={checkboxCss}
                  onChange={e => handleCheckbox(e.target.value)}
                />
                <label
                  htmlFor={cssName}
                  className={`block p-3 font-sans text-gray-800 bg-gray-300 rounded-3xl cursor-pointer m-2 border-4
                  `}
                >
                  {check.checkbox_feature}
                </label>
                {/* <span className="absolute -translate-y-11 left-5 w-3 h-3 rounded-full bg-gray-400 peer-checked/mobile:bg-mulberry"></span> */}
              </div>
            )
          })}
        </div>

        <label className="block font-sans text-white mt-5 ">
          {title_nbr_pages}
          <input
            name="nbrPages"
            type="number"
            onChange={e => setNbrPages(e.target.value)}
            className="w-full p-5 mt-2 text-3xl font-bold text-medallo-purpleLight rounded-3xl opacity-20 outline-none focus:opacity-100"
          />
        </label>
        <label className="block font-sans text-white mt-5 ">
          Email :
          <input
            required={true}
            name="email"
            type="email"
            onChange={e => setEmail(e.target.value)}
            className="w-full p-5 mt-2 text-2xl font-bold text-medallo-purpleLight rounded-3xl opacity-20 outline-none focus:opacity-100"
          />
        </label>
        <button
          type="submit"
          css={cssButtonGradient}
          className="px-4 py-3 mt-5 mx-auto font-sans font-bold text-white rounded-full border-solid border-4 cursor-pointer bg-medallo-purpleLight border-gray-300 "
        >{`${form_submit}  ${
          features.length >= 1 ? `(${features.length} options)` : ""
        }`}</button>
      </form>
    </>
  )
}

MedalloForm.propTypes = {}

const checkboxCss = css`
  &:checked + label {
    background: var(--medallo-purpleLight);
    color: white;
  }
`

const cssAnmim = keyframes`
0%{background-position:0% 50%}
50%{background-position:100% 50%}
100%{background-position:0% 50%}
`

const cssButtonGradient = css`
  &:hover {
    background: linear-gradient(30deg, #f4c82b, #ff6ad5);
    background-size: 400% 400%;
    animation: ${cssAnmim} 0.8s ease infinite;
  }
`

export default MedalloForm
